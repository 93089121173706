import Cookies from "js-cookie";
import store from "@/store";
// import { excelUploadMap } from "./upload.json";

import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
  MutationAction
} from "vuex-module-decorators";

import { IAppState, DeviceType } from "@/types";

@Module({ dynamic: true, store, name: "app" })
class App extends VuexModule implements IAppState {
  public sidebar = {
    opened: Cookies.get("sidebarStatus") !== "closed",
    withoutAnimation: false
  };
  public device = DeviceType.Desktop;
  public mobiledevice = DeviceType.Mobile;

  public hasError = false;
  public errorMessage = "";

  @Action({ commit: "TOGGLE_DEVICE" })
  public ToggleDevice(device: DeviceType) {
    return device;
  }

  @Action({ commit: "TOGGLE_SIDEBAR" })
  public ToggleSideBar(withoutAnimation: boolean) {
    return withoutAnimation;
  }

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device;
  }

  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    if (this.sidebar.opened) {
      Cookies.set("sidebarStatus", "closed");
    } else {
      Cookies.set("sidebarStatus", "opened");
    }
    this.sidebar.opened = !this.sidebar.opened;
    this.sidebar.withoutAnimation = withoutAnimation;
  }

  @MutationAction({ mutate: ["hasError", "errorMessage"] })
  public async showError(errorMessage: string) {
    return {
      hasError: true,
      errorMessage
    };
  }

  @MutationAction({ mutate: ["hasError", "errorMessage"] })
  public async dismissError() {
    return {
      hasError: false,
      errorMessage: ""
    };
  }
  get drawerTitle() {
    return "AG Children Foundation Limited";
  }

  // get excelUpload() {
  //   return excelUploadMap;
  // }

  // get errorMessage(): boolean {
  //   return this.errorMessage;
  // }
}

export const AppModule = getModule(App);
